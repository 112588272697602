import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import vuetify from './plugins/vuetify';
import Axios from "axios";

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
Axios.defaults.baseURL  = process.env.VUE_APP_AJAX_ROOT;

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
