<template>
  <v-card :color="listBGColor" :loading="loading">
    <!-- <component v-for="list in lists" :is="list" :key="list"></component> -->
    <List v-for="list in lists" :data="list" :key="list.id" @summaryUpdated="handleSummaryUpdated" :loading="loading"></List>

    <div v-if="loading"><br/><br/></div>
  </v-card>
</template>

<script>
  import axios from "axios";

  import List from "../lists/SingleListSummary.vue";

  export default {
    name: "ListsSummaryFull",
    components: { List },
    data: function() {
      return {
        lists: [],
        loading: true,
        baseListBGColor: "",
        loadingListBGColor: "grey lighten-1",
        /*
        return [
          {
            id: "binge",
            title: "Binge",
            show: {
              title: "The Magicians"
            }
          },
          {
            id: "hour",
            title: "Hour",
            show: {
              title: "Zoey's Extraordinary Playlist"
            }
          },
          {
            id: "halfhour",
            title: "Half Hour",
            show: {
              title: "Impractical Jokers: Inside Jokes"
            }
          }
        ];
        */

        //return [];
      }
    },
    computed: {
      listBGColor: function() {
        if(this.loading)  return this.loadingListBGColor;

        return this.baseListBGColor;
      }
    },
    methods: {
      getSummary: function() {
        this.loading = true;

        var self = this;
        self.lists = [];

        this.$http.get("lists/summary").then(function(response) {
          // handle success
          self.lists = response.data.lists;
          self.loading = false;
        });
      },
      handleSummaryUpdated: function() {
        this.getSummary();
      }
    },
    mounted: function() {
      this.getSummary();
    }
  };
</script>
