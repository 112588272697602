<template>
  <div>
    <img src="images/services/amazon/transparent-bg.png" width="100%" />
  </div>
</template>

<script>
  export default {
    name: "AmazonServiceSummary"
  };
</script>
