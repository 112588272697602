<template>
  <div v-bind:class="{mini: mini, skipped: data.showSkipped < 0, flagged: (data.flagged && listSet!='skipped')}">
    <div v-if="data.showName" class="container">
      <h2 class="text-center title">{{ data.showName }}</h2>
      <div v-if="data.showSkipped < 0" class="text-center teal--text skipped">Skipped {{ Math.abs(data.showSkipped) }} times</div>
      <div class="text-center subtitle-2 details">
        <div>{{ data.showService }}</div>

        <div v-if="data.listType == 'dayed'">
          <div v-if="data.showLastWatchDate"><abbr title="Last Watched">LW</abbr> {{ lastWatchFormatted }}</div>
          <div v-else>Never Watched</div>
        </div>
      </div>

      <div v-if="data.showNotes" class="text-center subtitle-2 note">
        <div><v-card elevation="0" class="mt-5 pa-1 indigo lighten-5" style="white-space: pre;" v-html="data.showNotes"></v-card></div>
      </div>

      <div v-if="actions[data.listId]" class="mt-5 text-center actions">
        <ul :style="btnListStyle">
          <li v-for="btnType in actions[data.listId]" v-bind:key="data.listId + btnType" :style='btnListItemStyle'>
            <template v-if="btnType == 'watch'">
              <v-btn elevation="2" outlined v-bind:x-small="mini" v-bind:small="!mini" class="ma-2 btn-watch" @click="watchShow(true)">Watch</v-btn>
            </template>
            <template v-else-if="btnType == 'watch-dont-count'">
              <v-btn elevation="2" outlined v-bind:x-small="mini" v-bind:small="!mini" class="ma-2 btn-watch-dont-count" @click="watchShow(false)">Watch (don't count)</v-btn>
            </template>
            <template v-else-if="btnType == 'skip' && !mini">
              <v-btn elevation="2" outlined v-bind:x-small="mini" v-bind:small="!mini" class="ma-2 btn-skip" @click="skipShow()">Skip</v-btn>
            </template>
            <template v-else-if="btnType == 'flag' && data.flagged">
              <v-btn elevation="2" outlined v-bind:x-small="mini" v-bind:small="!mini" class="ma-2 btn-skip" :color="flaggedBtnColor" @click="flagShow()"><v-icon>mdi-flag</v-icon></v-btn>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SingleListSummaryShow",
    props: {
      data: Object,
      mini: Boolean,
      listSet: String
    },
    data: function() {
      return {
        loading: false,
        actions: {
          hour: [ 'flag', 'watch', 'watch-dont-count', 'skip' ],
          halfhour: [ 'flag', 'watch', 'watch-dont-count', 'skip' ]
        },
        btnListStyle: {'list-style-type': 'none'},
        btnListItemStyle: { 'display': 'inline-block'}
      }
    },
    computed: {
      lastWatchFormatted: function() {
        var d = new Date(this.data.showLastWatchDate + " UTC+0000"); // Server sends/stores in UTC
        var now = new Date();

        /*
        console.log("D: " + d);
        console.log("Now: " + now);
        */

        var difference = Math.round((now - d) / 86400000, 0);

        return (
          d.getMonth() +
          1 +
          "." +
          d.getDate() +
          " (" +
          difference +
          " days ago)"
        );
      },
      flaggedBtnColor: function() {
        return this.data.flagged ? "yellow" : "";
      },
    },
    methods: {
      watchShow: function(countDate) {
        let self = this;
        this.loading = true;
        this.$http
          .post("shows/watch/" + this.data.showId, {
            countDate: countDate
          })
          .then(function(response) {
            self.loading = false;
            self.$emit("summaryUpdated");
            
            /*
            // handle success
            self.loading = false;
            self.$emit("listUpdated");
            self.scrollToListTop();
            */
          });
      },
      skipShow: function() {
        let self = this;
        this.loading = true;
        this.$http
          .post("shows/skip/" + this.data.showId)
          .then(function(response) {
            // handle success
            self.loading = false;
            self.$emit("summaryUpdated");

            /*
            self.$emit("listUpdated");
            self.scrollToListTop();
            */
          });
      },
      flagShow: function() {
        let self = this;
        this.loading = this.loadingColor;
        this.$http
          .post("shows/flagToggle/" + this.data.showId)
          .then(function(response) {
            // handle success
            self.loading = false;
            self.$emit("summaryUpdated");

            /*
            // handle success
            self.loading = false;
            self.$emit("listUpdated");
            self.scrollToListTop();
            */
          });
      },
    }
  };
</script>

<style>
  .skipped {
    border: 1px solid #999;
    background-color: #ccc;
  }

  .flagged {
    border: 1px solid #999;
    background-color: gold;
  }

  .mini {
    margin: 10px 0 20px;
  }

  .mini .container {
        display: grid;
        grid-template-areas:
            'title actions actions'
            'skipped details details'
            'note note note';
  }

  .mini .container .title {
    grid-area: title;
    font-size: 1rem!important;
  }

  .mini .container .skipped {
    grid-area: skipped;
    font-size: 0.875rem;
  }

  .mini .container .details {
    grid-area: details;
  }

  .mini .container .note {
    grid-area: note;
  }

  .mini .container .details > div {
    display: inline-block;
  }

  .mini .container .details > div:nth-child(2) {
    margin-left: 15px;
  }

  .mini .container .actions {
    grid-area: actions;
    margin-top: 0!important;
  }
</style>