<template>
  <v-data-table
    :headers="headers"
    :items="tableData"
    :items-per-page="100"
    class="elevation-1"
    :loading="isLoading"
    mobile-breakpoint="sm"
  >
    <template v-slot:item.watch_diff="{ item }">
      {{ Math.round((item.watch_diff / (60*60*24)) * 100) / 100 }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn icon color="red" @click="deleteHistory(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: "CurrentlyActiveSummary",
    components: { },
    computed: {
    },
    data: function() {
      return {
        headers: [
          { text: 'ID', align: 'right', value: 'id' },
          { text: 'Show ID', value: 'showid' },
          { text: 'List ID', value: 'listid' },
          { text: 'Previous Watch', value: 'previous_watch_time' },
          { text: 'This Watch', value: 'watch_time' },
          { text: 'Watch Difference', value: 'watch_diff', align: 'right' },
          { text: 'Actions', value: 'actions' }
        ],
        tableData: [],
        isLoading: false
      }
    },
    methods: {
      getHistory: function() {
        let self = this;
        this.isLoading = true;

        this.$http.get("lists/history").then(function(response) {
          self.tableData = response.data.history;
          self.isLoading = false;
        });
      },
      deleteHistory: function(id) {
        if(confirm("Really delete item " + id +"?")) {
          let self = this;
          this.isLoading = true;

          this.$http.delete("lists/history/" + id).then(function(response) {
            self.tableData = [];
            self.getHistory();
          });
        }
      }
    },
    mounted: function() {
      this.getHistory();
    }
  };
</script>
