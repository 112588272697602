<template>
  <span>
    <v-btn v-if="dialogId" elevation="2" outlined x-small @click="dialogBtnClicked">{{ text }}</v-btn>
    <v-btn v-if="routeId" text block :to="{ name: routeId }">{{ text }}</v-btn>
  </span>
</template>

<script>
  import { EventBus } from "../../events/EventBus.js";

  export default {
    name: "CardDialogButton",
    props: ["text", "icon", "dialogId", "routeId", "data", "dataId"],
    computed: {
      sendData() {
        let data = {
          id: this.dialogId,
          data: this.data,
          dataId: this.dataId,
          editingId: null
        };

        return data;
      }
    },
    methods: {
      dialogBtnClicked: function() {
        EventBus.$emit("open-dialog", this.sendData);
      }
    }
  };
</script>
