<template>
  <div>
    <v-app-bar app color="cyan" dark class="pr-md-10">
      <v-toolbar-title><v-btn to="/" text>TV Schedule</v-btn></v-toolbar-title>
      <v-spacer />

      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon color="yellow" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>

        <v-list>
          <ListItemDialogButton
            text="Show"
            icon="television"
            dialogId="addShow"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Movie"
            icon="filmstrip"
            dialogId="addMovie"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Game"
            icon="gamepad-variant-outline"
            dialogId="addGame"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="List"
            icon="format-list-bulleted"
            dialogId="addList"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Service"
            icon="video-account"
            dialogId="addService"
          ></ListItemDialogButton>
        </v-list>
      </v-menu>

      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon color="yellow" v-on="on">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>

        <v-list>
          <ListItemDialogButton
            text="Schedule"
            icon="timeline-text"
            routeId="scheduleSummaryTab"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="List"
            icon="format-list-bulleted"
            routeId="showLists"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Service"
            icon="video-account"
            routeId="showServices"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Watch History"
            icon="history"
            routeId="watchHistory"
          ></ListItemDialogButton>
        </v-list>
      </v-menu>
    </v-app-bar>

    <Dialogs></Dialogs>
  </div>
</template>

<script>
  import ListItemDialogButton from "../buttons/ListItemDialogButton.vue";
  import Dialogs from "../layout/Dialogs.vue";

  export default {
    name: "TopNav",
    components: {
      ListItemDialogButton,
      Dialogs
    },
    data: function() {
      return {
        drawer: false
      };
    }
  };
</script>
