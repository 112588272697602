<template>
  <div class="service-summary-item-container">
    <img :src="logoPath" width="100%" />
    <p>{{ data.name }}</p>
    <p>${{ data.monthlyPrice }}</p>
  </div>
</template>

<script>
  export default {
    name: "HuluServiceSummary",
    props: [ "data" ],
    computed: {
      logoPath: function() {
        return process.env.VUE_APP_IMAGE_PATH + this.data.mainLogo
      }
    }
  };
</script>

<style>
  .service-summary-item-container {
    /*
    border-bottom: 1px solid #bbb;
    padding-bottom: 20px;
    */
    border-top: 1px solid #bbb;
    padding-top: 20px;
  }
  .service-summary-item-container > img {
    margin-bottom: 5px;
  }
  .service-summary-item-container > p {
    margin: 0;
    text-align: center;
  }
</style>