<template>
  <v-app id="inspire">
    <MainNav></MainNav>

    <v-content>
      <v-container fluid>
        <main><router-view /></main>
      </v-container>
    </v-content>

    <Footer></Footer>
  </v-app>
</template>

<script>
  import MainNav from "@/components/layout/MainNav.vue";
  import Footer from "./components/layout/Footer.vue";

  import { EventBus } from "./events/EventBus.js";
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'

  export default {
    name: "App",
    components: { MainNav, Footer },
    data: function() {
      return {
        scrollData: {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
          easings: Object.keys(easings),
        }
      }
    },
    computed: {
      scrollOptions () {
        return {
          duration: this.scrollData.duration,
          offset: this.scrollData.offset,
          easing: this.scrollData.easing,
        }
      }
    },
    mounted: function() {
      EventBus.$on("scroll-window-to-pos", data => {
        console.log("Scroll event");
        this.$vuetify.goTo(data.offset, this.scrollOptions);
      });
    }
  };
</script>
