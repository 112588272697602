<template>
  <v-card>
    <v-card-title class="text-center d-block">Currently Active</v-card-title>
    <v-card-subtitle v-if="services" class="text-center">${{ totalPrice }}</v-card-subtitle>
    <v-card-text>
      <v-list>
        <v-list-item v-for="service in services" :key="service.serviceid">
          <v-list-item-content>
            <SingleServiceSummary :data="service"></SingleServiceSummary>
          </v-list-item-content>
        </v-list-item>

        <!-- 
        <v-list-item>
          <v-list-item-content>
            <hulu></hulu>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <amazon></amazon>
          </v-list-item-content>
        </v-list-item>
        -->
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  import hulu from "../services/hulu/Summary.vue";
  import amazon from "../services/amazon/Summary.vue";
  import SingleServiceSummary from "../services/SingleServiceSummary.vue";

  import axios from "axios";

  export default {
    name: "CurrentlyActiveSummary",
    components: { hulu, amazon, SingleServiceSummary },
    computed: {
      totalPrice: function() {
        let price = 0;

        for(let i in this.services) {
          price += this.services[i].monthlyPrice;
        }

        return Math.round(price*100) / 100;
      }
    },
    data: function() {
      return {
        services: null
      }
    },
    mounted: function() {
      var self = this;
      this.$http.get("services/summary")
        .then(function(response) {
          // handle success
          self.services = response.data.services;
        });
    }
  };
</script>
