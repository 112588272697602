<template>
  <v-container>
    <v-row>
      <!-- Name -->
      <v-col cols="12" md="12">
        <v-text-field
          label="Name *"
          v-model="value.name"
          required
        ></v-text-field>
      </v-col>
      <!-- Dayed? -->
      <v-col cols="12" md="6">
        <v-select
          v-model="value.dayed"
          :items="yesNoOptions"
          label="Dayed?"
          required
        ></v-select>
      </v-col>
      <!-- Diaplay in Summary? -->
      <v-col cols="12" md="6">
        <v-select
          v-model="value.display_in_summary"
          :items="yesNoOptions"
          label="Display In Summary?"
          required
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "AddListForm",
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data: function() {
      return {
        /*
        servicesOptions: ["0-17", "18-29", "30-54", "54+"],
        listOptions: ["0-17", "18-29", "30-54", "54+"],
        sublistOptions: ["0-17", "18-29", "30-54", "54+"],
        */
        yesNoOptions: [{text: "Yes", value: 1}, {text: "No", value: 0}]
      };
    },
    watch: {
      value() {
        this.$emit("input", this.value);
      }
    }
  };
</script>
