<template>
    <span>{{ dayName }}</span>
</template>

<script>
  export default {
    name: "DayNameConverter",
    props: [ "day" ],
    computed: {
      dayName: function() {
        if(this.day == 0)   return "Sunday";
        if(this.day == 1)   return "Monday";
        if(this.day == 2)   return "Tuesday";
        if(this.day == 3)   return "Wednesday";
        if(this.day == 4)   return "Thursday";
        if(this.day == 5)   return "FRiday";
        if(this.day == 6)   return "Saturday";

        return "Unknown Day";
      }
    },
    methods: {
    }
  };
</script>