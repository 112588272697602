import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import Schedule from "../views/Schedule";
import ScheduleSummaryTab from "../views/Schedule/SummaryTab";
import ScheduleDaysTab from "../views/Schedule/DaysTab";
import ScheduleNonDaysTab from "../views/Schedule/NonDaysTab";
import ShowLists from "../views/ShowLists";
import ShowServices from "../views/ShowServices";
import WatchHistory from "../views/WatchHistory";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
      path: "/schedule",
      name: "Schedule",
      component: Schedule,
      children: [
        {
          path: "", //selected tab by default
          name: "scheduleSummaryTab",
          component: ScheduleSummaryTab
        },
        {
          path: "days",
          component: ScheduleDaysTab
        },
        {
          path: "nondays",
          component: ScheduleNonDaysTab
        }
      ]
    },
    {
      path: "/lists",
      name: "showLists",
      component: ShowLists
    },
    {
      path: "/services",
      name: "showServices",
      component: ShowServices
    },
    {
      path: "/watchhistory",
      name: "watchHistory",
      component: WatchHistory
    }
];

const router = new VueRouter({
    mode: "history",
    routes: routes
});

export default router;
