<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col class="pa-2 order-last order-md-first" cols="12" lg="2" md="3">
          <CurrentlyActiveSummary></CurrentlyActiveSummary>
        </v-col>
        <v-col class="pa-2" cols="12" lg="10" md="9">
          <v-card>
            <SingleListSummary></SingleListSummary>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import CurrentlyActiveSummary from "../components/display/CurrentlyActiveSummary.vue";
  import SingleListSummary from "../components/lists/ListsSummary.vue";
  import { testfunc } from "@/js/utility.js";

  import axios from "axios";

  export default {
    name: "home",
    components: { CurrentlyActiveSummary, SingleListSummary },
    mounted: function() {
      console.log("here");
      console.log("ENV");
      console.log(process.env.VUE_APP_AJAX_ROOT);
      testfunc();

      /*
      this.$http.post("lists/upsert", {
        lists: [
          {
            id: "test_list",
            name: "A Test List",
            dayed: 0
          },
          {
            id: "test_list_2",
            name: "A Second Test List",
            dayed: 1
          }
        ]
      });
      */

      // axios.delete("http://localhost/_git/tv-schedule-2020/server/public/lists/5");
    },
    data: function() {
      return {
        color: "success",
      }
    }
  };
</script>
