<template>
  <ShowLists :apiId="apiId"></ShowLists>
</template>

<script>
  import ShowLists from "../../components/display/ShowTiles/ShowLists.vue";

  export default {
    name: "ScheduleDaysTab",
    components: { ShowLists },
    data: function() {
      return {
        apiId: "schedule-days"
      }
    }
  };
</script>
