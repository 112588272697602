<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        Add Movie
      </v-card-title>

      <v-card-text>
        <AddMovieForm v-model="form"></AddMovieForm>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="testValue()">
          Add Show
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import AddMovieForm from "../forms/AddMovie.vue";
  import { EventBus } from "../../events/EventBus.js";

  export default {
    name: "AddMovieDialog",
    components: { AddMovieForm },
    data: function() {
      return {
        id: "addMovie",
        dialog: false,
        editingId: null,
        form: {
          title: "",
          service: "",
          list: "",
          sublist: ""
        }
      };
    },
    methods: {
      testValue: function() {
        console.log(this.form);
      }
    },
    mounted: function() {
      EventBus.$on("open-dialog", data => {
        if (data.id == this.id) {
          this.dialog = true;
        }
      });
    }
  };
</script>
