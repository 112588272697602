<template>
  <v-container>
    <v-row>
      <!-- Title -->
      <v-col cols="12" md="6">
        <v-text-field
          label="Title*"
          v-model="value.title"
          required
        ></v-text-field>
      </v-col>
      <!-- Service -->
      <v-col cols="12" md="6">
        <v-select
          v-model="value.service"
          :items="servicesOptions"
          label="Service*"
          required
        ></v-select>
      </v-col>
      <!-- List -->
      <v-col cols="12" md="6">
        <v-select
          v-model="value.list"
          :items="listOptions"
          label="List*"
          required
        ></v-select>
      </v-col>
      <!-- Sub-List -->
      <v-col cols="12" md="6">
        <v-select
          v-model="value.sublist"
          :items="sublistOptions"
          label="Sub-List*"
          required
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "AddMovieForm",
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data: function() {
      return {
        servicesOptions: ["0-17", "18-29", "30-54", "54+"],
        listOptions: ["0-17", "18-29", "30-54", "54+"],
        sublistOptions: ["0-17", "18-29", "30-54", "54+"]
      };
    },
    watch: {
      value() {
        this.$emit("input", this.value);
      }
    }
  };
</script>
