<template>
  <div>
    <AddShowDialog></AddShowDialog>
    <AddMovieDialog></AddMovieDialog>
    <AddGameDialog></AddGameDialog>
    <AddListDialog></AddListDialog>
    <AddServiceDialog></AddServiceDialog>
  </div>
</template>

<script>
  import AddShowDialog from "../dialogs/AddShow.vue";
  import AddMovieDialog from "../dialogs/AddMovie.vue";
  import AddGameDialog from "../dialogs/AddGame.vue";
  import AddListDialog from "../dialogs/AddList.vue";
  import AddServiceDialog from "../dialogs/AddService.vue";

  export default {
    name: "Dialogs",
    components: {
      AddShowDialog,
      AddMovieDialog,
      AddGameDialog,
      AddListDialog,
      AddServiceDialog
    },
    data: function() {
      return {
        drawer: false
      };
    }
  };
</script>
