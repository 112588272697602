<template>
  <v-list-item>
    <v-btn v-if="dialogId" text block @click="dialogBtnClicked">
      <v-list-item-action>
        <v-icon>mdi-{{ icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-title>{{ text }}</v-list-item-title>
    </v-btn>
    <v-btn v-if="routeId" text block :to="{ name: routeId }">
      <v-list-item-action>
        <v-icon>mdi-{{ icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-title>{{ text }}</v-list-item-title>
    </v-btn>
  </v-list-item>
</template>

<script>
  import { EventBus } from "../../events/EventBus.js";

  export default {
    name: "ListItemDialogButton",
    props: ["text", "icon", "dialogId", "routeId", "data", "dataId"],
    computed: {
      sendData() {
        let data = {
          id: this.dialogId,
          data: this.data,
          dataId: this.dataId,
          editingId: null
        };

        return data;
      }
    },
    methods: {
      dialogBtnClicked: function() {
        EventBus.$emit("open-dialog", this.sendData);
      }
    }
  };
</script>
