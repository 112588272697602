<template>
  <v-footer color="cyan" app>
    <v-spacer />
    <span class="white--text">&copy; 2019</span>
  </v-footer>
</template>

<script>
  export default {
    name: "Footer"
  };
</script>
